import gsap, { Power1 } from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import { Shffl } from '../_app/cuchillo/components/Shffl';
import { isDebug } from '../_app/cuchillo/core/Basics';

export default class Preloader {
    static container;
    static number;
    static progress;
    static shffl = [];
    static _percentage = 0;
    static _isShow = false;
    static callHide;

    static get percentage() { return this._percentage; }
    static set percentage(__n) {
        this._percentage = __n;
        this.number.innerHTML = `[${Math.ceil(this._percentage).toString().padStart(3, '0')}%]`;
    }

    static init() {
        this.container = GetBy.id('Preloader');
        this.number = GetBy.class('__number', this.container)[0];
        this.text = GetBy.class('text', this.container)[0];
        this.progress = GetBy.class('progress', this.container)[0];

        [...GetBy.selector('[data-shffl]', this.container)].map(item => {
            const shffl = new Shffl(item, {
                origin: Shffl.LEFT,
                duration: 1,
                textFrom: '',
                textTo: item.innerText
            });
            this.shffl.push(shffl);
        });
    }

    static show(__call) {
        for (let i = 0; i < this.shffl.length; i++) {
            gsap.delayedCall(.4 * i, () => {
                this.shffl[i].play();
            });
        }

        //FAKE PROGRESS
        gsap.to(this, {
            percentage: 89, duration: 20, delay: 2, onStart: () => {
                this._isShow = true;
                if (this.callHide) this.callHide();
                else if (__call) __call();
            }
        });
    }

    static update(__progress) { }

    static hide(__call) {
        this._isLoaded = true;
        if (!this._isShow) {
            this.callHide = () => {
                this.hide(__call);
            }
            return;
        }

        const cb = () => {
            let delay = 1.5;
            for (let i = 0; i < this.shffl.length; i++) {
                gsap.delayedCall(delay + .3 * i, () => {
                    this.shffl[i].reverse();
                });
            }

            gsap.to(this.container, {
                duration: .6,
                opacity: 0,
                delay: delay + 1.4,
                onComplete: () => {
                    this.container.style.display = 'none';
                    if (__call) __call();
                }
            });
        }

        if (isDebug) {
            this.container.style.display = 'none';
            if (__call) __call();
        } else {
            gsap.killTweensOf(this);
            gsap.to(this, {
                percentage: 100, duration: .4, onComplete: () => {
                    cb();
                }
            });
            /*
            
                        gsap.delayedCall(1.8, () => {
                            this.animateNumber(cb);
                        });*/
        }
    }
}
